<template>
  <LeftSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-green h1">Wi-Fi and Its Potency</h2>
      <p class="desc">
        Wi-Fi can be defined as the modern technique and an enterprise network
        solution that connects a massive number of devices at several locations.
        It is mainly used in homes, offices and organizations. Moreover,
        Wireless connections lower the costs of expenditure and remove the
        difficulties one has to face when using cables for connectivity. EJAF
        enables the configuration, sending a safe remote system for information
        and voice traffic that flawlessly coordinates with center system
        empowering portability to clients.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/networking/enterprise-network/enterprise-network-solutionsimg-3.png"
        alt="Wi-Fi and Its Potency"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "third-enter-section",
};
</script>
