<template>
  <RightSection>
    <div class="info-desc col-lg-8 pt-3">
      <h2 class="title-yellow h1">Unified Communication Solutions</h2>
      <p class="desc">
        A unified communication solution embeds deeply into the basic and
        advanced organization functions. For instance, present information,
        chats and internal protocols of communication. It also highlights the
        firm’s capability of augmentation of portability like web conferencing
        through video and audio. Instant sharing of work data and information,
        whiteboards and controlled call. Not to mention, incorporated phone
        messages, email and tele-copying. We at Ejaf have a wide range of
        abilities to coordinate various methods of communications into different
        system stages that will help in empowering the clients to accomplish
        unified communications.
      </p>
    </div>
    <div class="info-img col-lg-4 text-center">
      <img
        class="w-100"
        src="../../../../assets/networking/enterprise-network/enterprise-network-solutionsimg-6.png"
        alt="Wan Accelerator"
      />
    </div>
  </RightSection>
</template>

<script>
export default {
  name: "sixth-enter-section",
};
</script>
