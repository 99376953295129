<template>
  <enterprise-network />
</template>

<script>
import EnterpriseNetwork from "../../components/solutions/networking/EnterpriseNetwork.vue";
export default {
  components: { EnterpriseNetwork },
  name: "enterprise-network-page",
};
</script>

<style></style>
