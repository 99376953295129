<template>
  <RightSection>
    <div class="info-desc col-lg-8 pt-3">
      <h2 class="title-yellow h1">Campus Area Network</h2>
      <p class="desc">
        It is a network used to create a link amid different buildings. It has
        many components, but mainly it consists of two [LAN] within a specific
        location. Used in many different places. For instance, at schools and
        college campuses, at industrial areas, at army and military campuses, at
        various organizational campuses and many more locations. EJAF is very
        beneficial as it provides enterprise network solutions and ways to
        design and execute huge campus area networks, which in return upgrades,
        secures the system. Also, it makes sure that this network has high
        availability.
      </p>
    </div>
    <div class="info-img col-lg-4 mb-0 mb-sm-3 text-center">
      <img
        class="w-100"
        src="../../../../assets/networking/enterprise-network/enterprise-network-solutionsimg-2.png"
        alt="Campus Area Network"
      />
    </div>
  </RightSection>
</template>

<script>
export default {
  name: "second-enter-section",
};
</script>
