<template>
  <RightSection>
    <div class="info-desc col-lg-8 pt-3">
      <h2 class="title-yellow h1">Network and Its Supervision</h2>
      <p class="desc">
        Network supervision means the scheme that is required to handle
        different computer networks. EJAF, by working with its partners,
        provides an enterprise network solution and an opportunity to oversee
        the functioning networks and control it from centralized configurations.
      </p>
    </div>
    <div class="info-img col-lg-4 text-center">
      <img
        class="w-100"
        src="../../../../assets/networking/enterprise-network/enterprise-network-solutionsimg-4.png"
        alt="Network and Its Supervision"
      />
    </div>
  </RightSection>
</template>

<script>
export default {
  name: "fourth-enter-section",
};
</script>
