<template>
  <LeftSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-orange h1">Data Center Networks</h2>
      <p class="desc">
        When it comes to data center networks, they are the most crucial part of
        the networking to a data center. These networks act as a connection
        between all the constituents of data resources. Datacenter networks must
        be logical, systematic, climbable and sharp enough to manage the
        increasing demand for computing. High optimum power by enterprises can
        be acquired easily by increasing the use of data center networks. EJAF
        provides an enterprise network solution by designing and executing many
        systems, including data centers and local area networks and the sites
        quickly recovered from any mishap.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/networking/enterprise-network/enterprise-network-solutionsimg-1.png"
        alt="Data Center Networks"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "first-enter-section",
};
</script>
