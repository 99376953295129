<template>
  <LeftSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-green h1">Software Defined Networks</h2>
      <p class="desc">
        It is a modern technique extracted by administrators of a network, which
        helps manage all the network services through a reflection of more
        elevated level usefulness. EJAF is the system integrator that provides
        beneficial enterprise network solutions by allowing the complete
        execution of software-defined networks spanning the structure and its
        practice.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/networking/enterprise-network/enterprise-network-solutionsimg-5.png"
        alt="Software Defined Networks"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "fiveth-enter-section",
};
</script>
